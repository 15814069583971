import { Route, Routes } from 'react-router-dom';

import cx from 'classnames';

import { useSelector } from 'reduxStore/hooks';

import Preload from 'components/preload/preload';
import Toast from 'components/toast/toastProvider';

import PageNotFound from 'pages/pageNotFound/pageNotFound';

import style from './app.module.scss';

function App() {
  const { user } = useSelector(state => state.auth);

  const classNames = cx(style.app, {
    [style.loggedIn]: Boolean(user),
  });

  return (
    <div className={classNames}>
      {/* <AppHeader /> */}
      <Routes>
        <Route element={<Preload />}>
          {/* <Route path={signInUrl} element={<SignIn />} />
          <Route exact path="/signin" component={SignIn} /> */}
          {/* <Route path="/signin" element={<SignIn />} />
          <Route path="/invite/:unique_code" element={<SignIn />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route element={<Protected />}>
            <Route path={dashboardUrl} element={<Dashboard />} />
            <Route path="/consumer/*" element={<Consumer />} />
            <Route path="/contributor/*" element={<Contributor />} />
            <Route
              path={subscriptionResultUrl}
              element={<SubscriptionResult />}
            />
          </Route> */}
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
      {/* <AppFooter /> */}
      <Toast />
    </div>
  );
}

export default App;
