import comingSoon from 'images/coming-soon.png';

export default function PageNotFound() {
  // const navigate = useNavigate();
  return (
    <div>
      <img
        src={comingSoon}
        alt="Collab.Land Dev Portal"
        style={{
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
        }}
      />
      {/* <h2 className={style.title}>404 Page not found</h2> */}
      {/* <div className={style.goBackButtonWrapper}>
        <Button outline onClick={() => navigate(dashboardUrl)}>
          Return to Dashboard
        </Button>
      </div> */}
    </div>
  );
}
